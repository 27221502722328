.screen-wrapper {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .ant-card {
    width: 80vw !important;
  }
}

@media only screen and (min-width: 1024px) {
  .ant-card {
    width: 50vw !important;
  }
}

@media only screen and (max-width: 512px) {
  .ant-card {
    width: 95vw !important;
  }
}

.ant-card {
  border-radius: 10px;
  height: 50vh;

  text-align: center;
}

.ant-card-body {
  width: 100%;
  height: 100%;
}

.note-input {
  height: 65% !important;
}

.link-button {
  position: absolute;
  right: 75px;
  top: 1em;

  width: 40px;
  height: 40px;
  padding: 0px;

  border-radius: 30px;

  -webkit-animation: fadein 150ms;

          animation: fadein 150ms;
}

.refresh-button {
  position: absolute;
  right: 24px;
  top: 1em;

  width: 40px;
  height: 40px;
  padding: 0px;

  border-radius: 30px;
}

.spinning {
  -webkit-animation: spin 500ms linear infinite;
          animation: spin 500ms linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

